import {
  Box,
  Flex,
  Image,
  Icon,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/react';
import { SiHulu, SiNetflix, SiPrimevideo } from 'react-icons/si';
const Accolade = (props) => {
  return (
    <Flex
      p="0"
      w="100%"
      h="auto"
      justifyContent="center"
      alignSelf="center"
      spacing="1"
    >
      <ButtonGroup
        variant="solid"
        color="white"
        spacing={['5', null, '10']}
      >
        <IconButton
          as="a"
          href="#"
          aria-label="A&E"
          icon={
            <Icon
              as={Image}
              src="a_e.png"
              size="50%"
              w="90%"
              height="90%"
            />
          }
          size="lg"
          _hover={{
            bgGradient: `linear(to-l,#635a59,white)`,
          }}
        />
        <IconButton
          as="a"
          href="#"
          aria-label="Hulu"
          w="100%"
          icon={<SiHulu color="black" size="100%" />}
          size="lg"
          _hover={{
            bgGradient: `linear(to-l,#635a59,white)`,
          }}
        />

        <IconButton
          as="a"
          href="#"
          aria-label="{props.label}"
          w="100%"
          icon={<SiNetflix color="black" size="100%" />}
          size="lg"
          _hover={{
            bgGradient: `linear(to-l,#635a59,white)`,
          }}
        />
        <IconButton
          as="a"
          href="#"
          w="100%"
          aria-label="{props.label}"
          icon={
            <Icon
              as={Image}
              src="disc+.png"
              size="100%"
              w="100%"
              height="100%"
              opacity={'.9'}
            />
          }
          size="lg"
          _hover={{
            bgGradient: `linear(to-l,#635a59,white)`,
          }}
        />
        <IconButton
          as="a"
          href="#"
          aria-label="{props.label}"
          w="100%"
          icon={<SiPrimevideo size="100%" color="black" />}
          size="lg"
          _hover={{
            bgGradient: `linear(to-l,#635a59,white)`,
          }}
        />
      </ButtonGroup>
    </Flex>
  );
};

export default Accolade;

//   <Image
//     fallbackSrc="https://via.placeholder.com/100x55"
//     src="../src/images/01.jpeg"
//     fit="cover"
//     width="100%"
//     h="auto"
//     border="none"
//   />
