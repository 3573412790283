import {
  Box,
  Flex,
  ButtonGroup,
  Container,
  IconButton,
  Stack,
  HStack,
  Text,
  Image,
  Spacer,
} from '@chakra-ui/react';
import { withTheme } from '@emotion/react';
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaTwitter,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <Container
      maxW="100vw"
      color="white"
      bgGradient={`linear(to-r,#635a59,#010103)`}
      m="0"
      p="0"
      py="1"
    >
      <Flex
        py="2"
        flexDirection={['column', null, 'column']}
        flexWrap="wrap"
        textAlign="center"
      >
        <Box
          as="button"
          fontSize="2xl"
          fontWeight="500"
          borderBottom="2px"
          borderTop="2px"
          py="2"
          //px="4"
          _hover={{ bgGradient: `linear(to-l,#635a59,#010103)` }}
        >
          Recipes
        </Box>
      </Flex>
      <Container
        as="footer"
        role="contentinfo"
        py={{ base: '8', md: '12' }}
      >
        <Stack spacing="5">
          <HStack
            justify="center"
            direction="row"
            align="center"
            w="100%"
          >
            <ButtonGroup variant="ghost" spacing={['5', null, '10']}>
              <IconButton
                as="a"
                href="#"
                w="100%"
                aria-label="FaceBook"
                icon={<FaFacebookSquare fontSize="3rem" />}
                _hover={{
                  bgGradient: `linear(to-r,#635a59,white)`,
                }}
              />
              <Spacer />
              <IconButton
                as="a"
                href="#"
                w="100%"
                aria-label="Instagram"
                icon={<FaInstagramSquare fontSize="3rem" />}
                _hover={{
                  bgGradient: `linear(to-r,#635a59,white)`,
                }}
              />
              <Spacer />
              <IconButton
                as="a"
                href="#"
                w="100%"
                aria-label="Twitter"
                icon={<FaTwitter fontSize="3rem" />}
                _hover={{
                  bgGradient: `linear(to-r,#635a59,white)`,
                }}
              />
              <Spacer />
            </ButtonGroup>
          </HStack>
          <Flex justifyContent="center" py="2">
            <Text
              noOfLines={1}
              fontSize={['8px', null, '18px']}
              color="subtle"
            >
              &copy; {new Date().getFullYear()} Cast Iron Chef, Inc.
              All rights reserved.
            </Text>
          </Flex>
        </Stack>
      </Container>
    </Container>
  );
};
export default Footer;
