import React from 'react';

import {
  Container,
  Image,
  Box,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';

const Hero = () => {
  return (
    <Flex
      maxW="100%"
      flexDirection={['column', null, 'row']}
      px="2"
      bg="black"
      justifyContent={['center', null, 'center']}
      bgGradient={`linear(to-r,#635a59,#010103)`}
    >
      <Image
        src="atilio1.jpg"
        w={['100%', null, '100%']}
        h={['100%', null, '100%']}
        alignSelf="center"
        px="5"
      />
      <Flex
        fontFamily={`"Kalam", cursive`}
        fontWeight="600"
        flexDirection={['column', null, 'column']}
        alignSelf={['center', null, 'space-evenly']}
        flexWrap="0"
        fontSize={['12px', null, '22px']}
        height="auto"
        py="2"
      >
        <Text
          color="white"
          px="1"
          style={{
            textIndent: '1rem',
          }}
        >
          When you think of cast iron, you think of all those skillet
          dishes and wonderful home cooked breakfast staples - eggs,
          bacon, sausage. But what if I told you, that you can cook
          anything you want, any kind of dish, in a cast iron pan and
          not only can you do it, but it will taste better than any
          other you've made it before? Pizza, steak, burgers,
          frittatas, pancakes, and even grilled salads - you name it.
          I've spent decades cooking and learning how to transform all
          types of recipes into cast iron cuisine delights to serve up
          in my restaurants and now I want to show you how you can do
          it right in your own house very easily.
          <Text
            color="white"
            px="1"
            style={{
              textIndent: '1rem',
            }}
          >
            I get sometimes it can be intimidating to work with cast
            iron pans if you never have before because you hear all
            types of things about seasoning and how to clean them
            properly, but its actually quite simple. Not only is the
            cast iron pan the perfect tool to cook with, its the
            perfect platter to serve your food in as well. No need to
            scrape food out to put it on a platter. The cast iron pan
            is your platter and it will keep your food warm and fresh
            for you to finish your meal and go back for seconds right
            there on the table and better yet, the food won't stick so
            you can enjoy every last bite.
          </Text>
          <Text
            color="white"
            px="1"
            style={{
              textIndent: '1rem',
            }}
          >
            Thank you for allowing me to share my passion with you and
            I hope to show you all the ways you too can become a cast
            iron chef and make your next meal the best one you've had.
            Join me as I walk you through some of my favorite recipes
            and explore inside the kitchen and out. Cast a spell on
            your next gathering and wow them with the magic of cast
            iron. My name is Attilioh Marini, and I am the Cast Iron
            Chef.
          </Text>
        </Text>
        {/* <Image
        src="atilio4.jpg"
        w="50vw"
        h="50vw"
        alignSelf="center"
        px="5"
      /> */}
        <Container
          fontFamily={`"Homemade Apple", cursive`}
          py="2"
          flexDirection={'row'}
        >
          <Text
            noOfLines="1"
            align="center"
            fontSize={['18px', null, '48px']}
            fontWeight="800"
            color="white"
            //textShadow={'-1px 1px #fce0de'}
            //m="5"
            py="4"
          >
            Chef Atilio Marini
          </Text>
        </Container>
      </Flex>
    </Flex>
  );
};

export default Hero;
/* <video
        controls
        autoPlay=""
        muted=""
        playsInline=""
        width="100%"
        height="100%"
        preload="auto"
        src={''}
        type="video/mp4"
      ></video>*/
