import {
  Box,
  Stack,
  Heading,
  Flex,
  Text,
  Button,
  useDisclosure,
  Container,
  Collapse,
  Image,
  IconButton,
  Icon,
  ButtonGroup,
  Spacer,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaTwitter,
} from 'react-icons/fa';

const NavBar = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen());

  return (
    <Flex
      m="0"
      pr="4"
      maxW="100vw"
      justifyContent="center"
      alignSelf="center"
      bg="black"
      borderBottom="1px solid black"
    >
      <Flex
        maxW="100vw"
        justifyContent="space-between"
        alignItems="center"
      >
        <Image
          src="logocast.png"
          h="auto"
          bgGradient={`linear(to-r,#635a59,#010103)`}
        />
      </Flex>
      <Spacer />
      <Flex alignSelf="center">
        <ButtonGroup
          variant="ghost"
          color="white"
          spacing={['.5', null, '10']}
        >
          <IconButton
            as="a"
            href="#"
            aria-label="FaceBook"
            icon={<FaFacebookSquare size="3vw" />}
            _hover={{
              bgGradient: `linear(to-b,#635a59,white)`,
            }}
          />
          <IconButton
            as="a"
            href="#"
            aria-label="Instagram"
            icon={<FaInstagramSquare size="3vw" />}
            _hover={{
              bgGradient: `linear(to-b,#635a59,white)`,
            }}
          />
          <IconButton
            as="a"
            href="#"
            aria-label="Twitter"
            icon={<FaTwitter size="3vw" />}
            _hover={{
              bgGradient: `linear(to-b,#635a59,white)`,
            }}
          />
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};
export default NavBar;
// return (
//   <Container
//     maxW="container.xl"
//     w="100%"
//     p="0"
//     //position="sticky"
//     //opacity=".3"
//   >
//     <Flex
//       as="nav"
//       align="center"
//       justify="space-between"
//       wrap="wrap"
//       padding="1"
//       bg="#fffdfc"
//       color="#1c1514"
//       w="100%"
//       left="0"
//       //top={[0, null, '21px']}
//       top={0}
//       position={['relative', null, 'fixed']}
//       //position="fixed"
//       zIndex="100"
//       //bgImg={RtPIcon}
//       {...props}
//     >
//       <Flex align="center" height="100%">
//         {/* <Heading as="h1" size="lg" letterSpacing={'tighter'}>
//           <Text textShadow={'-1px 2px #ab8282'}>
//             Cast Iron Chef
//           </Text>
//         </Heading> */}
//         <Image
//           src={RtPIcon}
//           height="75px"
//           width="100%"
//           opacity="1"
//         />
//       </Flex>

//       <Box
//         display={{
//           base: 'block',
//           md: 'none',
//           padding: '0',
//           margin: '0',
//         }}
//         onClick={handleToggle}
//       >
//         <HamburgerIcon
//           sx={{
//             color: isOpen ? 'white' : 'black',
//             _hover: {
//               color: 'white',
//             },
//           }}
//         />
//       </Box>

//       {/* <Collapse in={isOpen} animateOpacity>
//         <Container> */}
//       <Stack
//         direction={{ base: 'column', md: 'row' }}
//         display={{
//           base: isOpen ? 'block' : 'none',
//           md: 'flex',
//         }}
//         width={{ base: 'full', md: 'auto' }}
//         alignItems="center"
//         flexGrow={1}
//         mt={{ base: 4, md: 0 }}
//       >
//         <Text>Docs</Text>
//         <Text>Examples</Text>
//         <Text>Blog</Text>
//       </Stack>
//       {/* </Container>
//       </Collapse> */}

//       <Box
//         display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
//         mt={{ base: 4, md: 0 }}
//       >
//         <Button
//           variant="outline"
//           sx={{
//             _hover: {
//               borderColor: 'teal.700',
//               color: 'white',
//             },
//           }}
//         >
//           Create account
//         </Button>
//       </Box>
//     </Flex>
//   </Container>
